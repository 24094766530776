import { AESUtil } from './aes.util';
import { CacheUtil } from './cache.util';
export class AuthUtil {
    public static getToken() {
        const token = CacheUtil.get('token');
        if (token)
            return AESUtil.Descrypt(token);
    }
    public static setToken(token: string) {
        CacheUtil.set('token', AESUtil.Encrypt(token));
    }
    public static signOut() {
        CacheUtil.remove('token');
    }
}