import { Injectable } from '@angular/core';
import { PathUtil } from '../../util/path.util';
import { HttpService } from '../../service/http.service';

@Injectable()
export class LoginService {
    constructor(private http: HttpService) {

    }
    login(data: any) {
        return this.http.post(PathUtil.verify, data);
    }
}