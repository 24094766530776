import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.layout.component.html',
    styleUrls: ['./home.layout.component.less']
})

export class HomeLayoutComponent implements OnInit {
    isCollapsed: boolean = false;
    user: any = {
        name: "admin"
    }
    constructor(private router: Router) { }

    ngOnInit() { 

        this.user.name = localStorage.getItem("loginName");
    }

    exit() {
        localStorage.removeItem('token');
        localStorage.removeItem('loginName');
        this.router.navigateByUrl('/login');
    }
}