import { Injectable } from '@angular/core';
import { ResponseType } from '../models/response.model';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    constructor(private msgSrv: NzMessageService) {

    }
    alert(message: string, type: number = 2): void {
        switch (type) {
            case 0:
                this.msgSrv.success(message);
                break;
            case 1:
                this.msgSrv.warning(message);
                break;
            default:
                this.msgSrv.error(message);
                break;
        }

    }


}