import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AES, enc, mode, pad, MD5 } from 'crypto-js';
export class AESUtil {
    private static KEY: string = environment.aes.cs.key;
    private static IV: string = environment.aes.cs.iv;

    public static Descrypt(data: string): any {
        if (data) {
            try {
                var iv = enc.Utf8.parse(this.IV);
                var key = enc.Utf8.parse(this.KEY);
                var cfg = {
                    mode: mode.CBC,
                    iv: iv,
                    padding: pad.Pkcs7
                };
                let bearer = AES.decrypt(data, key, cfg).toString(enc.Utf8);
                return bearer;
            }
            catch (err) {
                return null;
            }
        }
        return null;
    }

    public static Encrypt(data: string) {
        if (data) {
            try {
                var iv = enc.Utf8.parse(this.IV);
                var key = enc.Utf8.parse(this.KEY);
                var encrypted = AES.encrypt(data, key,
                    {
                        iv: iv,
                        mode: mode.CBC,
                        padding: pad.Pkcs7
                    });
                return encrypted.toString();   //返回的是base64格式的密文
            }
            catch (err) {
                return "";
            }
        }
        return "";
    }

    public static GetMD5(data: string) {
        return MD5(data);
    }
}