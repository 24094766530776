<div class="login">
    <div class="login-area">
        <div class="login-logo-warp">
            <img src="../../../assets/images/logo.png">
        </div>
        <div class="login-box-warp">
            <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group nzPrefixIcon="user">
                            <input type="text" nz-input formControlName="userName" placeholder="Username" />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please input your Password!">
                        <nz-input-group nzPrefixIcon="lock">
                            <input type="password" nz-input formControlName="password" placeholder="Password" />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control>
                        <button nz-button class="login-form-button" [nzType]="'primary'" [nzLoading]="isLoading">
                            <i nz-icon nzType="login"></i>登录
                        </button>
                        <a class="login-form-forgot">忘记密码</a>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>      
    </div>
</div>