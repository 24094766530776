export class CacheUtil {
    public static get(key: string): any {
        let data = localStorage.getItem(key);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    }
    public static set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    public static remove(key: string) {
        localStorage.removeItem(key);
    }
    public static clear() {
        localStorage.clear();
    }
}