import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzTransferModule } from 'ng-zorro-antd/transfer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzDropDownModule,
        NzFormModule,
        NzButtonModule,
        NzInputModule,
        NzCheckboxModule,
        NzIconModule,
        NzModalModule,
        NzInputNumberModule,
        NzTableModule,
        NzCascaderModule,
        NzImageModule,
        NzSelectModule,
        NzToolTipModule,
        NzSpinModule,
        NzPaginationModule,
        NzSpaceModule,
        NzUploadModule,
        NzTagModule,
        NzDividerModule,
        NzListModule,
        NzAvatarModule,
        NzTypographyModule,
        NzCardModule,
        NzCollapseModule,
        NzSelectModule,
        NzTabsModule,
        NzDescriptionsModule,
        NzResultModule,
        NzPopoverModule,
        NzPopconfirmModule,
        NzDatePickerModule,
        NzTabsModule,
        NzSkeletonModule,
        NzLayoutModule,
        NzEmptyModule,
        NzStatisticModule,
        NzAlertModule,
        NzBreadCrumbModule,
        NzRadioModule,
        NzSwitchModule,
        NzTreeSelectModule,
        NzMessageModule,
        NzTimePickerModule,
        NzTransferModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzDropDownModule,
        NzFormModule,
        NzButtonModule,
        NzInputModule,
        NzCheckboxModule,
        NzIconModule,
        NzModalModule,
        NzInputNumberModule,
        NzTableModule,
        NzCascaderModule,
        NzImageModule,
        NzSelectModule,
        NzToolTipModule,
        NzSpinModule,
        NzPaginationModule,
        NzSpaceModule,
        NzUploadModule,
        NzTagModule,
        NzDividerModule,
        NzListModule,
        NzTypographyModule,
        NzAvatarModule,
        NzCardModule,
        NzCollapseModule,
        NzSelectModule,
        NzTabsModule,
        NzDescriptionsModule,
        NzResultModule,
        NzPopoverModule,
        NzPopconfirmModule,
        NzDatePickerModule,
        NzTabsModule,
        NzSkeletonModule,
        NzLayoutModule,
        NzEmptyModule,
        NzStatisticModule,
        NzAlertModule,
        NzBreadCrumbModule,
        NzRadioModule,
        NzSwitchModule,
        NzTreeSelectModule,
        NzMessageModule,
        NzTimePickerModule,
        NzTransferModule
    ],
})
export class SharedModule { }