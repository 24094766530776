export const environment = {
    production: true,
    version: '1.0.210303',
    // host: 'https://rcapi.windpayer.com/',
    host: 'https://dev.rcapi.windpayer.com/',
    aes: {
        cs: {
            key: '%Tuna210623inQc$',
            iv: '$CqIN326012ANUt%'
        }
    }
};