import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/service/auth-guard.service';
import { HomeLayoutComponent } from './home.layout.component';

const routes: Routes = [
    {
        path: '',
        component: HomeLayoutComponent,
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        children: [
            { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
            { path: 'dashboard', loadChildren: () => import('../../pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'monitorsetting', loadChildren: () => import('../../pages/monitorsetting/monitorsetting.module').then(m => m.MonitorsettingModule) },
            { path: 'riskmanagement', loadChildren: () => import('../../pages/riskmanagement/riskmanagement.module').then(m => m.RiskmanagementModule) },
            { path: 'riskrate', loadChildren: () => import('../../pages/riskrate/riskrate.module').then(m => m.RiskrateModule) },
            { path: 'mainbody', loadChildren: () => import('../../pages/mainbody/mainbody.module').then(m => m.MainbodyModule) },
            { path: 'accountmanagement', loadChildren: () => import('../../pages/accountmanagement/accountmanagement.module').then(m => m.AccountmanagementModule) },
            { path: 'namelist', loadChildren: () => import('../../pages/namelist/namelist.module').then(m => m.NamelistModule) },
            { path: 'negativelist', loadChildren: () => import('../../pages/negativelist/negativelist.module').then(m => m.NegativelistModule) },
            { path: 'datasettinglist', loadChildren: () => import('../../pages/datasettinglist/datasettinglist.module').then(m => m.DataSettingListComponentModule) },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HomeRoutingModule { }